<template>
  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th>#</th>
          <th>URAIAN</th>
          <th>JENJANG</th>
          <th>CAPAIAN</th>
          <th>AKSI</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(prestasi, num) in sourceData"
          :key="prestasi.id"
        >
          <td>{{ num + 1 }}</td>
          <td><label class="margin-b-0">{{ prestasi.nm_kualifikasi }}</label><br>{{ prestasi.nm_prestasi.toUpperCase() }}</td>
          <td>{{ prestasi.jenjang }}</td>
          <td>{{ prestasi.capaian }}</td>
          <td>
            <button
              type="button"
              class="btn btn-success box-shadow btn-rounded btn-sm"
              @click="handleSelectDokumen(prestasi)"
            >
              Dokumen
            </button>
          </td>
        </tr>
        <tr
          v-show="!sourceData.length"
        >
          <td colspan="5">
            Tidak ada data prestasi yang ditampilkan
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { getPrestasi } from '@/services/prestasi.service'
import { defineComponent, reactive, toRefs, computed } from 'vue'
export default defineComponent({
  props: {
    profil: {
      type: Object,
      default () {
        return { id_sms: '', id_reg_pd: '' }
      }
    }
  },
  emits: ['handle-loading', 'select-record'],
  setup (props, { attrs, slots, emit, expose }) {
    const state = reactive({
      sourceData: {},
      isLoading: false
    })

    const loading = computed(() => {
      return state.isLoading
    })

    const handlePrestasi = () => {
      emit('handle-loading', true)
      getPrestasi({ id_reg_pd: props.profil.id_reg_pd }).then(response => {
        state.sourceData = response?.records || []
        emit('handle-loading', false)
      })
    }

    const handleSelectDokumen = (source) => {
      emit('select-record', source)
    }

    return {
      ...toRefs(state),
      loading,
      handlePrestasi,
      handleSelectDokumen
    }
  },
  created () {
    this.handlePrestasi()
  }
})
</script>


<template>
  <loading
    :active="loading"
    :is-full-page="fullPage"
  />
  <div
    v-show="isPageUpdateDokumen"
    class="row"
  >
    <div class="col-sm-12 col-md-6">
      <div class="card radius-20">
        <div class="card-header card-default">
          <p
            id="idExsampel"
            class="text-muted"
          >
            Halaman Informasi Data Prestasi Mahasiswa
          </p>
        </div>
        <div class="card-body padding-t-0">
          <update-dokumen
            v-if="isPageUpdateDokumen"
            :profil="users"
            :source-file="sourceDokumen"
            @handle-upload="handleUpload"
            @handle-loading="handleLoading"
            @back="backPageUpdateDokumen"
          />
        </div>
      </div>
    </div>
  </div>
  <div
    v-show="isTabelPrestasi"
    class="row"
  >
    <div class="col-sm-12 col-md-12">
      <div class="card radius-lr20">
        <div class="card-header card-default">
          <p
            id="idExsampel"
            class="text-muted"
          >
            Halaman Informasi Data Prestasi Mahasiswa
          </p>
        </div>
        <div class="card-body padding-t-0">
          <tabel-prestasi
            :profil="users"
            @select-record="handlePageUpdateDokumen"
            @handle-loading="handleLoading"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import Loading from 'vue-loading-overlay'
import TabelPrestasi from '@/components/prestasi/TabelPrestasi.vue'
import UpdateDokumen from '@/components/prestasi/UpdateDokumen.vue'
import { useStore } from 'vuex'
import { defineComponent, reactive, toRefs, computed } from 'vue'
export default defineComponent({
  name: 'PrestasiMahasiswa',
  components: {
    Loading,
    TabelPrestasi,
    UpdateDokumen
  },
  data () {
    const store = useStore()
    const users = store.getters.user

    const state = reactive({
      sourceDokumen: {},
      isTabelPrestasi: true,
      isPageUpdateDokumen: false,
      isLoading: false
    })

    const loading = computed(() => {
      return state.isLoading
    })

    return {
      ...toRefs(state),
      users,
      loading,
      errorMsg: '',
      fullPage: true
    }
  },
  methods: {
    handleLoading (source) {
      this.isLoading = source
    },
    handlePageUpdateDokumen (source) {
      this.isPageUpdateDokumen = true
      this.isTabelPrestasi = false
      this.sourceDokumen = source
    },
    backPageUpdateDokumen () {
      this.isPageUpdateDokumen = false
      this.isTabelPrestasi = true
    },
    handleUpload (source) {
      /* global $ */
      if (source.error_code === 200) {
        $.toast({
          heading: 'Good job!',
          text: 'Dokumen prestasi berhasil di unggah',
          position: 'top-right',
          loaderBg: '#fff',
          icon: 'success',
          hideAfter: 3000,
          stack: 1
        })
      } else {
        $.toast({
          heading: 'Error !',
          text: source.messages,
          position: 'top-right',
          loaderBg: '#fff',
          icon: 'error',
          hideAfter: 3000,
          stack: 1
        })
      }
    }
  }
})
</script>


<template>
  <slot name="header" />
  <form
    role="form"
    method="post"
    action=""
  >
    <small class="text-muted">Kualifikasi</small>
    <p>{{ sourceFile.nm_kualifikasi }}</p>
    <small class="text-muted">Nama Prestasi </small>
    <p>{{ sourceFile.nm_prestasi }}</p>
    <div class="form-group">
      <label>Berkas </label>
      <div
        class="fileinput fileinput-new input-group col-md-12"
        data-provides="fileinput"
      >
        <span
          class="input-group-addon btn btn-indigo btn-file "
          style="border-radius: 4px 0px 0px 4px;"
        >
          <span class="fileinput-new">File</span>
          <span class="fileinput-exists">Change</span>
          <input
            ref="file"
            type="file"
            @change="beforeUpload()"
          >
        </span>
        <a
          href="#"
          class="input-group-addon btn btn-danger  hover fileinput-exists"
          data-dismiss="fileinput"
        >Remove</a>
        <div
          class="form-control"
          data-trigger="fileinput"
        >
          <span class="fileinput-filename" />
        </div>
      </div>
    </div>
    <br>
    <button
      type="button"
      class="btn btn-warning btn-rounded box-shadow"
      @click="handleBack"
    >
      Kembali
    </button>&nbsp;
    <button
      type="button"
      class="btn btn-success btn-rounded box-shadow"
      @click="handleUpload"
    >
      Simpan Dokumen
    </button>
  </form>
</template>
<script>
import { putDokPrestasi } from '@/services/prestasi.service'
import { defineComponent, reactive, toRefs, computed, ref } from 'vue'
import { useStore } from 'vuex'
export default defineComponent({
  props: {
    profil: {
      type: Object,
      default () {
        return { id_sms: '', id_reg_pd: '' }
      }
    },
    sourceFile: {
      type: Object,
      default () {
        return {
          id: '',
          capaian: '',
          id_reg_pd: '',
          id_sms: '',
          jenjang: '',
          kualifikasi: '',
          lampiran: '',
          nm_kualifikasi: '',
          nm_prestasi: '',
          tanggal: '',
          tempat: '',
          uraian_ind: null
        }
      }
    }
  },
  emits: ['handle-loading', 'handle-upload', 'back'],
  setup (props, { attrs, slots, emit, expose }) {
    const store = useStore()
    const users = store.getters.user
    const fileList = ref([])
    const field = reactive({
      id_sms: '',
      id_reg_pd: '',
      kualifikasi: '',
      uraian: '',
      tempat: '',
      tanggal: '',
      jenjang: '',
      capaian: '',
      files: '',
      keterangan: ''
    })
    const state = reactive({
      pembimbing: {},
      isLoading: false
    })

    const handleBack = () => {
      emit('back')
    }

    const handleUpload = () => {
      const formData = new FormData()
      formData.append('id', props.sourceFile.id)
      formData.append('files', fileList.value)
      emit('handle-loading', true)
      putDokPrestasi(formData).then(response => {
        emit('handle-upload', response)
        emit('handle-loading', false)
        if (response.error_code === 200) {
          field.files = ''
        }
      }).catch(() => {
        emit('handle-loading', false)
      })
    }

    const loading = computed(() => {
      return state.isLoading
    })
    return {
      ...toRefs(state),
      users,
      field,
      fileList,
      loading,
      handleUpload,
      handleBack
    }
  },
  methods: {
    beforeUpload () {
      this.fileList = this.$refs.file.files[0]
      return false
    }
  }
})
</script>
